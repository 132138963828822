.main-content>div:nth-child(3)>div>.row>div>.login_setting_div {
    border: 0px !important;
    background-color: transparent !important;
    padding: 0px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    margin-bottom: 0px !important;
}

.main-content>div:nth-child(5)>div:nth-child(2)>.row>div>.login_setting_div:nth-child(3) {
    border: 0px !important;
    background-color: transparent !important;
    padding: 0px !important;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    display: none;
    box-shadow: none !important;
}

.main-content>div:nth-child(5)>div:nth-child(2)>.row>div>.login_setting_div:nth-child(4) {
    border: 0px !important;
    background-color: transparent !important;
    padding: 0px !important;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    box-shadow: none !important;
    display: none;
}

.main-content>div:nth-child(5)>div:nth-child(3)>.row>div>.login_setting_div:nth-child(3) {
    border: 0px !important;
    background-color: transparent !important;
    padding: 0px !important;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    display: none;
    box-shadow: none !important;
}

.main-content>div:nth-child(5)>div:nth-child(3)>.row>div>.login_setting_div:nth-child(4) {
    border: 0px !important;
    background-color: transparent !important;
    padding: 0px !important;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    display: none;
    box-shadow: none !important;
}

.main-content>div:nth-child(5)>div:nth-child(4)>.row>div>.login_setting_div:nth-child(4) {
    border: 0px !important;
    background-color: transparent !important;
    padding: 0px !important;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    display: none;
    box-shadow: none !important;
}

.main-content>div:nth-child(5)>div:nth-child(5)>.row>div>.login_setting_div:nth-child(4) {
    border: 0px !important;
    background-color: transparent !important;
    padding: 0px !important;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    display: none;
    box-shadow: none !important;
}

.main-content>div:nth-child(5)>div:nth-child(6)>.row>div>.login_setting_div:nth-child(4) {
    border: 0px !important;
    background-color: transparent !important;
    padding: 0px !important;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    display: none;
    box-shadow: none !important;
}

.login_setting_div {
    box-shadow: 0px 0px 6px #1a1a1a17 !important;
    border-radius: 20px !important;
    border: 0px !important;
}

.first_sec {
    width: 100%;
}

.first_sec~div {
    display: none !important;
}

.main-content>div:nth-child(3)>.setting_bor>.row>div>.login_setting_div {
    box-shadow: 0px 0px 6px #1a1a1a17 !important;
    border-radius: 20px !important;
    padding: 15px !important;
    margin-bottom: 20px !important;
    background-color: #fff !important;
    border: 0px !important;
}

.swiper~div~div~div>div>div:nth-child(1)>.row>div:nth-child(1)>.login_setting_div {
    box-shadow: none !important;
    border-radius: 0px !important;
    border: 0px !important;
    background-color: transparent !important;
    display: none;
}

.swiper~div~div~div>div>div:nth-child(1)>.row>div:nth-child(1)>.login_setting_div:nth-child(1) {
    /* box-shadow: none !important;
    border-radius: 0px !important;
    border: 0px !important;
    background-color: transparent !important; */
    display: block !important;
}

.swiper~div~div~div>div>div:nth-child(1)>.row>div:nth-child(2)>.row>div {
    display: block !important;
}

.swiper~div~div~div>div>div:nth-child(1)>.row>div:nth-child(2)>.row>div.first_sec {
    width: 33.33333333%;
}

.main-content>.center~div>div>.row>div>.row>.first_sec {
    width: 33.33333333% !important;
}

.main-content>.center~div>div>.row>div>.row>div {
    display: block !important;
}

.main-content>.center~div>.setting_bor>.row>div>.row>.first_sec {
    width: 100% !important;
}

.main-content>.center~div>.setting_bor>.row>div>.row>.first_sec~div {
    display: none !important;
}

.main-content>.center~div>.setting_bor_1>.row>div:nth-child(1)>.login_setting_div {
    border: 0px !important;
    background-color: transparent !important;
    padding: 0px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    margin-bottom: 0px !important;
}

.div_none>div:nth-child(3) {
    display: none !important;
}

.Application~.setting_bor>.row>div>.login_setting_div:nth-child(4) {
    display: none !important;
}

.div_none_1>div:nth-child(3)>.login_setting_div:nth-child(5) {
    display: none !important;
}

.div_none_1>div:nth-child(3)>.login_setting_div:nth-child(6) {
    display: none !important;
}

.div_none_1>div:nth-child(3)>.login_setting_div:nth-child(7) {
    display: none !important;
}

.div_none_1>div:nth-child(3)>.login_setting_div:nth-child(8) {
    display: none !important;
}

.setting_bor>.row>div:nth-child(2) {
    justify-content: center;
}

.login_setting_div>h1 {
    font-size: 50px;
    color: #042154;
    margin: 0 0 10px;
    font-weight: 900;
    letter-spacing: 0px;
}

.login_setting_div>h5 {
    color: #042154;
    margin: 0 0 10px;
    font-weight: 600;
}

.Key_project~div>.row>div:nth-child(1)>.login_setting_div>h1 {
    font-size: 1.25rem;
    color: #042154;
    margin: 0 0 10px;
    font-weight: 600;
}

.para_setting~p {
    height: 210px !important;
}

.reverse_div_content {
    flex-flow: row-reverse !important;
    padding-bottom: 60px;
}

.reverse_div_content_1 {
    padding-bottom: 60px;
}

.swiper-slide-active>div>div>.row>.logo_background_change>img {
    background: #042154;
    border-radius: 10px;
    padding: 10px;
}

.logo_background_change>img {
    background: #042154;
    border-radius: 10px;
    padding: 10px;
}

.changeing_ceo_div_main {
    background-color: #FEFEFE;
}

.changeing_ceo_div {
    background-color: #F0F9FF;
    padding: 60px 30px;
    border-radius: 25px;
}

.changeing_ceo_div>img {
    margin-bottom: 30px;
}

.changeing_ceo_div>p {
    width: 62%;
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 25px;
    color: #000D24;
}

.changeing_ceo_div>h5{
    font-size: 20px;
    font-weight: 400!important;
}

.changeing_ceo_div>p:nth-child(4) {
    margin-bottom: 0px;
    color: #979797;
}